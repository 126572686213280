<template>
    <div id="loading-bg">
      <div class="loading-logo">
        <img src="/logo.png" alt="Logo" />
      </div>
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
</template>
<script>
  export default {
    async mounted() {
      var code = this.$route.query.code
      this.$http.post('/fg/in/ml/auth/callback', { auth: { partner: 'ml', code: code } })
        .then(response => {
          console.log('auth success', error)

        })
        .catch(error => {
          console.log('auth error', error)
        })
    }
  }
</script>